footer {
  position: relative;
  z-index: 10; /* Ensure the footer is always on top of other content, including pillars */
}

:root {
  --pillar-scale: 1.2; /* Default scaling factor, adjust this to resize the pillars */
}

.main-content {
  position: relative;
  z-index: 3;
}

body {
  overflow-x: hidden;
}

@media (min-width: 1024px) {
  .bg-background-image {
    position: relative;
    background-color: transparent;
    display: flex;
    flex-direction: column; /* Aligns top, middle, and bottom vertically */
    height: auto; /* Ensure dynamic height */
    padding: 0;
    margin: 0;
  }

  .pillar-left,
  .pillar-right {
    position: absolute; /* Ensure it scrolls with the content */
    top: 0;
    bottom: 0;
    width: calc(
      340px * var(--pillar-scale)
    ); /* Scale width based on variable */
    display: flex;
    flex-direction: column; /* Aligns top and middle */
    z-index: 2; /* Make sure it stays behind content */
  }

  .pillar-left {
    left: -120px;
    top: 64px;
  }

  .pillar-right {
    right: -120px;
    top: 64px;
    transform: scaleX(-1); /* Mirrors the right pillar horizontally */
  }

  /* For the top part of the pillar */
  .pillar-top {
    background-image: url('../public/top_pillar.png');
    background-repeat: no-repeat;
    height: calc(
      569px * var(--pillar-scale)
    ); /* Scale height based on variable */
    width: calc(
      340px * var(--pillar-scale)
    ); /* Scale width based on variable */
    background-position: top;
    background-size: calc(340px * var(--pillar-scale))
      calc(569px * var(--pillar-scale)); /* Scale background image */
  }

  /* For the middle, repeatable part */
  .pillar-middle {
    background-image: url('../public/middle_pillar.png');
    background-repeat: repeat-y; /* Repeat vertically */
    background-position: top;
    width: calc(
      340px * var(--pillar-scale)
    ); /* Scale width based on variable */
    flex-grow: 1; /* Allow the middle to grow dynamically */
    background-size: calc(340px * var(--pillar-scale)) auto; /* Scale the background image width, let height repeat */
  }

  /* Hide the pillars on mobile */
  @media (max-width: 1023px) {
    .pillar-left,
    .pillar-right {
      display: none;
    }
  }
}

.text-facebook {
  color: #3b5998;
} /* Facebook blue */
.text-twitter {
  color: #1da1f2;
} /* Twitter blue */
.text-linkedin {
  color: #0077b5;
} /* LinkedIn blue */
.text-whatsapp {
  color: #25d366;
} /* WhatsApp green */
