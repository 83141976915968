/* Home.css */

.topic-card {
  position: relative;
  overflow: hidden;
}

.topic-card h3 {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-align: center;
}

.topic-card:hover .topic-image {
  opacity: 0.75;
}

.topic-image {
  transition: opacity 0.12s ease-in-out;
  opacity: 0;
  z-index: 0;
}
