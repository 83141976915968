/* src/components/Navbar.css */
.navbar {
  background-color: #333;
  color: white;
  padding: 1rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-brand {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-left: 1rem;
}

.navbar .nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.navbar .nav-link:hover {
  color: #ddd;
}

.gg-shopping-cart {
  display: block;
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 21px;
  background: linear-gradient(to left, currentColor 12px, transparent 0)
      no-repeat -1px 6px/18px 2px,
    linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 6px
      14px/11px 2px,
    linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 0 2px/4px
      2px,
    radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 12px
      17px/4px 4px,
    radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 6px
      17px/4px 4px;
}

.gg-shopping-cart::after,
.gg-shopping-cart::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 2px;
  height: 14px;
  background: currentColor;
  top: 2px;
  left: 4px;
  transform: skew(12deg);
}

.gg-shopping-cart::after {
  height: 10px;
  top: 6px;
  left: 16px;
  transform: skew(-12deg);
}

.gg-shopping-bag {
  transform: scale(var(--ggs, 1));
}
.gg-shopping-bag,
.gg-shopping-bag::after {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
}
.gg-shopping-bag::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
  border-top: 0;
  top: 2px;
  left: 3px;
}

.gg-trash {
  box-sizing: border-box;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px; /* Adjust width */
  height: 12px; /* Adjust height */
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset -2px 0 0, inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}
.gg-trash::after,
.gg-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.gg-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px;
}
.gg-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
  }

  .navbar-links {
    flex-direction: column;
    align-items: center;
  }

  .navbar-links li {
    margin: 0.5rem 0;
  }
}
