@tailwind base;
@tailwind components;
@tailwind utilities;

.main-container {
  min-height: calc(100vh - 136px);
}

.lesser-container {
  min-height: calc(50vh - 140px);
}

.loading {
  @apply opacity-0;
}

.loaded {
  @apply opacity-100 transition-opacity duration-100;
}
